<template>
  <div class="section_sticky" :class="{ 'section_sticky--is-bottom': isStickyBottom }">
    <div class="box_sticky">
      <div class="inner_section">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sticky",
  data() {
    return {
      lastScollPosition: 0,
      isRaf: false,
      isStickyBottom: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onSticky);
    window.addEventListener("resize", this.onSticky);
    this.onStickyBottom();
  },

  methods: {
    onSticky(e) {
      this.lastScollPosition = window.scrollY;
      if (!this.isRaf) {
        window.requestAnimationFrame(this.onStickyBottom);
      }
      this.isRaf = true;
    },
    onStickyBottom() {
      this.isRaf = false;
      const isSticky = this.$el.getBoundingClientRect().bottom >= window.innerHeight;
      this.isStickyBottom = !isSticky;
    },
  },
};
</script>

<style scoped lang="scss">
.section_sticky {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 104px;
  box-shadow: 0 -2px 4px 0 rgba(34, 34, 34, 0.15);
  z-index: 10;
  &--is {
    &-bottom {
      box-shadow: none;
    }
  }
}
.section_sticky .box_sticky {
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  text-align: center;
}
.section_sticky .inner_section {
  overflow: hidden;
  padding: 32px 0;
  font-size: 0;
}
.section_sticky button + button,
.section_sticky button + a {
  margin-left: 8px;
}
</style>
