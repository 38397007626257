import moment from "moment";
import LocalStorageManager from "@/LocalStorageManager";
import { getMoneyToNumber } from "@/utils/stringUtils";
import InspectItemModel from "@/components/admin/inspect/write/InspectItemModel.js";

export default class InspectModel {
  constructor() {
    this.objRaw = {};

    this.inspectYn = LocalStorageManager.shared.getIsInspect();
    this.docId = "";
    this.isRefPk = false;

    this.inspectNum = ""; //검수ID
    this.currencyCode = "KRW";
    this.totalPrice = 0;
    this.balance = 0;
    this.contractInfo = {}; //계약 정보
    this.orderInfo = {}; //발주 정보
    this.requestCid = "-"; // 문서번호
    this.histories = []; //히스토리
    this.description = "";
    this.rejectText = "";
    this.inspectItemList = [];
    this.degreeSumList = [];
    this.deleteOrderGoodsList = []; // 납품취소내역

    this.contentsHistory = ""; // 납품내역
    this.deliveryCompleteDate = null; // 납품완료일필수항목
    this.inspectEndDate = null; // 검수완료일필수항목
    this.attachFileList = []; // 검수 증빙 문서
    this.etc = ""; //의견
    this.orderGoodsList = [];
    this.stateCode = "";
    this.degree = "";
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);
    const {
      orderCid,
      truncCurrency,
      stateCode,
      orderInspectList,
      inspectItemList,
      degreeSumList,
      degree,
      totalPrice,
      balance,
      orderGoodsList,
      deleteOrderGoodsList,
    } = obj;

    this.orderCid = orderCid;
    if (inspectItemList && inspectItemList.length < 1) {
      this.inspectItemList = orderGoodsList.map((item) => {
        return {
          ...item,
          inspectUnitPrice: item.price,
          price: item.totalPrice,
          deliveryQuantity: String(0),
          inspectAmount: String(0),
        };
      });
    } else {
      this.inspectItemList = inspectItemList.map((item) => {
        return {
          ...item,
          inspectPrice: String(item.inspectPrice),
          inspectUnitPrice: String(item.inspectUnitPrice),
          deliveryQuantity: String(item.deliveryQuantity),
          inspectAmount: String(item.inspectAmount),
        };
      });
    }
    this.totalPrice = totalPrice;
    this.balance = balance;
    this.truncCurrency = truncCurrency;
    this.stateCode = stateCode;
    this.degreeSumList = degreeSumList;
    this.histories = orderInspectList;
    this.orderInspectList = orderInspectList; //삭제예정
    this.degree = degree;
    this.orderGoodsList = orderGoodsList;
    if (orderInspectList?.length > 0) {
      this.docId = orderInspectList[0].orderCid;
    }
    this.deleteOrderGoodsList = deleteOrderGoodsList || [];
  }
  setInspectItemListWithDegreeMax(obj) {
    let inspectItemListInfoForView = _.cloneDeep(obj);
    inspectItemListInfoForView.sort((a, b) => {
      return b.degree - a.degree;
    });

    const { degree } = inspectItemListInfoForView[0];
    return inspectItemListInfoForView.filter((item) => {
      return item.degree == degree;
    });
  }

  setInspectItemInfoData(obj) {
    const { orderGoodsList, inspectItemList, stateCode } = obj;
    const degreeListWithDegreeZero = inspectItemList.filter((item) => item.degree === 0).reverse();
    const lengthNum = orderGoodsList.length - 1;

    let degreeListWithDegreeMax =
      inspectItemList.length == 0 ? [] : this.setInspectItemListWithDegreeMax(inspectItemList);

    const orderGoodsInspectList = orderGoodsList.reduce((acc, item, index) => {
      const goodsNum = item.goodsNum;

      // 납품수량 : 담당자가 입력한 납품수량 or 발주수량 or 파트너사가 입력한 납품수량 (degree = 0)
      let deliveryQuantity =
        (degreeListWithDegreeZero.length == 0 && degreeListWithDegreeMax.length > 0) ||
        (degreeListWithDegreeZero.length > 0 && degreeListWithDegreeMax.length > 0)
          ? degreeListWithDegreeMax.find((item) => item.goodsNum === goodsNum).deliveryQuantity
          : degreeListWithDegreeZero.length == 0 && degreeListWithDegreeMax.length == 0
          ? (deliveryQuantity = item.quantity)
          : degreeListWithDegreeZero.find((item) => item.goodsNum === goodsNum).deliveryQuantity;

      // 합격수량 : 담당자가 입력한 합격수량
      let { inspectAmount } =
        degreeListWithDegreeMax.length > 0
          ? degreeListWithDegreeMax.find((item) => item.goodsNum === goodsNum)
          : { inspectAmount: 0 };

      // 검수금액 : 담당자가 입력한 검수금액
      let { inspectPrice } =
        degreeListWithDegreeMax.length > 0
          ? degreeListWithDegreeMax.find((item) => item.goodsNum === goodsNum)
          : { inspectPrice: 0 };

      // 검수요청전 일 경우, 초기화
      if (stateCode == "INITIAL") {
        deliveryQuantity = 0;
        inspectAmount = 0;
        inspectPrice = 0;
      }

      // 검수요청 일 경우, 합격수량, 검수금액 초기화
      if (stateCode == "INSPECT_REQUEST") {
        deliveryQuantity = degreeListWithDegreeZero[lengthNum - index].deliveryQuantity;
        inspectAmount = 0;
        inspectPrice = 0;
      }

      acc = [
        ...acc,
        {
          ...item,
          deliveryQuantity: deliveryQuantity,
          inspectAmount: inspectAmount,
          inspectPrice: inspectPrice,
        },
      ];
      return acc;
    }, []);
    this.orderGoodsList = orderGoodsInspectList;
  }

  setInspectInfo(obj) {
    const { contentsHistory, deliveryCompleteDate, inspectFile } = obj;
    this.contentsHistory = contentsHistory;
    this.deliveryCompleteDate = deliveryCompleteDate;
    this.attachFileList = inspectFile;
  }

  setOrderInfoData(obj) {
    const {
      data,
      companyName, //파트너사
    } = obj;
    const {
      goodsDate, // 발주일
      goodsDeadline, // 납품기한
      title, //발주명
      inspectCompleteDate, //검수완료일,
    } = obj;

    this.orderInfo = {
      goodsDate,
      goodsDeadline,
      title,
      companyName,
      inspectCompleteDate,
    };
  }

  getData() {
    const updateInspectItemList = this.inspectItemList.map((item) => {
      return {
        ...item,
        orderCid: this.orderCid,
        inspectNum: this.inspectNum,
        inspectPrice: getMoneyToNumber(item.inspectPrice),
      };
    });

    const obj = {
      attachFileList: this.attachFileList,
      contentsHistory: this.contentsHistory, //검수내역
      deliveryCompleteDate: moment(this.deliveryCompleteDate).format("YYYYMMDD"), //납품완료일
      orderCid: this.orderCid,
      inspectNum: this.inspectNum,
      updateInspectItemList: updateInspectItemList || [],
    };

    return Object.assign({}, obj);
  }

  getAlertMessage() {
    if (!this.contentsHistory) return "검수내역을 입력해주세요.";
    if (!this.deliveryCompleteDate) return "납품완료일을 선택해주세요.";
    if (this.attachFileList.length < 1) return "검수 증빙 문서을 첨부해주세요.";

    return null;
  }
}
