<template>
  <div class="area_view">
    <TableHead title="검수내역" />
    <Board :disableHover="true" :dataList="dataList" :showPagination="false">
      <template v-slot:colgroup>
        <!-- 일시 -->
        <col style="width: 132px" />
        <!-- 상태 -->
        <col style="width: 120px" />
        <!-- 검수내역 -->
        <!-- <col /> -->
        <!-- 의견 -->
        <col />
        <!-- 담당자 -->
        <col style="width: 180px" />
        <!-- 첨부파일 -->
        <col style="width: 300px" />
      </template>
      <template v-slot:tr>
        <th>일시</th>
        <th>상태</th>
        <!-- <th>검수내역</th> -->
        <th>의견</th>
        <th>담당자</th>
        <th>첨부파일</th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in dataList">
          <InspectConfirmPopupLine :key="index" :rowData="item" />
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="5" class="td_empty">검색 결과가 없습니다</td>
        </tr>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "@/components/common/board/Board";
import TableHead from "@/components/shared/TableHead.vue";

import InspectConfirmPopupLine from "./InspectConfirmPopupLine";

export default {
  name: "InspectHistory",
  components: {
    Board,
    TableHead,
    InspectConfirmPopupLine,
  },
  props: {
    dataList: Array,
    isInspect: Boolean, // fasle 일때 가운데 테이블 노출
    cancelYn: Boolean, // false 일때 검수 증빙 문서 노출
  },
  computed: {
    styleHeight() {
      if (this.isInspect) {
        // 649
        if (this.cancelYn) {
          return "352px";
        } else {
          return "246px";
        }
      } else {
        // 673
        if (this.cancelYn) {
          return "205px";
        } else {
          return "99px";
        }
      }
    },
  },
};
</script>
