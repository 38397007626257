<template>
  <tr>
    <td class="align_top">
      {{ rowData.degree }}
    </td>
    <td class="align_top">
      {{ rowData.inspectCompleteDate | dateStringFormat }}
    </td>
    <td class="align_top td_ellip align_right">
      {{ rowData.degreeSum | currency }}
    </td>
    <td class="align_top">
      <!-- {{ rowData.regId || '' }} -->
    </td>
  </tr>
</template>

<script>
import ListLineMixin from "@/mixins/ListLineMixin";

import { getDateStringWithTime } from "@/utils/dateUtils";

import inspectStatusList from "@/constants/inspectStatusList";
import FileView from "@/components/common/file/FileView";

export default {
  name: "InspectConfirmPopupLine",
  components: {
    FileView,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  computed: {
    regDateWithTime() {
      const { regDate } = this.rowData;
      if (!regDate) return "";

      return getDateStringWithTime(regDate);
    },
    stateCodeName() {
      const { stateCodeName, stateCode } = this.rowData;
      if (stateCodeName) return stateCodeName;

      const result = inspectStatusList.find((item) => item.code === stateCode);
      if (!result) return "";

      return result.text;
    },
  },
};
</script>
