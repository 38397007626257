<template>
  <div class="area_view">
    <TableHead title="기검수내역" />
    <Board
      :disableHover="true"
      :dataList="dataList"
      :scrollHeight="styleHeight"
      :showPagination="false"
    >
      <template v-slot:colgroup>
        <col style="width: 232px" />
        <!-- No -->
        <col style="width: 204px" />
        <!-- 검수일자 -->
        <col style="width: 218px" />
        <!-- 금액 -->
        <col style="width: 588px" />
        <!-- 비고 -->
      </template>
      <template v-slot:tr>
        <th style="width: 232px">No</th>
        <th style="width: 204px">검수일자</th>
        <th style="width: 218px">금액</th>
        <th style="width: 578px">비고</th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in dataList">
          <InspectPriceHistoryLine :key="index" :rowData="item" />
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="4" class="td_empty">검수 내역이 없습니다</td>
        </tr>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "@/components/common/board/Board";
import InspectPriceHistoryLine from "./InspectPriceHistoryLine";
import TableHead from "@/components/shared/TableHead.vue";

export default {
  name: "InspectPriceHistory",
  components: {
    Board,
    TableHead,
    InspectPriceHistoryLine,
  },
  props: {
    dataList: Array,
    isInspect: Boolean, // fasle 일때 가운데 테이블 노출
    cancelYn: Boolean, // false 일때 검수 증빙 문서 노출
  },
  computed: {
    styleHeight() {
      if (this.isInspect) {
        // 649
        if (this.cancelYn) {
          return "352px";
        } else {
          return "246px";
        }
      } else {
        // 673
        if (this.cancelYn) {
          return "205px";
        } else {
          return "99px";
        }
      }
    },
    // totalPrice(){
    //   dataList.forEach(item => {
    //     item.
    //   });
    // }
  },
};
</script>
