<template>
  <tr>
    <td class="align_top">
      {{ regDateWithTime }}
    </td>
    <td class="align_top">
      {{ rowData.stateCodeName }}
    </td>
    <!-- <td class="align_top align_left">
      {{ rowData.contentsHistory | nullToDash }}
    </td> -->
    <td class="align_top align_left td_preline">
      {{ computedEtcOrReason }}
    </td>
    <td class="align_top">
      <template v-if="!$isPartner">
        <!--어드민-->
        {{ `${rowData.regId ? `${rowData.regName} (${rowData.regId})` : rowData.regName}` }}
      </template>
      <template v-else>
        <!-- 파트너 -->
        {{ `${rowData.regName ? `${rowData.regName}` : rowData.regId}` }}
      </template>
    </td>
    <td>
      <FileView
        class="file_ellip"
        :dataList="rowData.attachFileList ? rowData.attachFileList : []"
      />
    </td>
  </tr>
</template>

<script>
import ListLineMixin from "@/mixins/ListLineMixin";

import { getDateStringWithTime } from "@/utils/dateUtils";

import inspectStatusList from "@/constants/inspectStatusList";
import FileView from "@/components/common/file/FileView";

export default {
  name: "InspectConfirmPopupLine",
  components: {
    FileView,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  computed: {
    regDateWithTime() {
      const { regDate } = this.rowData;
      if (!regDate) return "";

      return getDateStringWithTime(regDate);
    },
    computedEtcOrReason() {
      const { stateCode, reason, etc, contentsHistory } = this.rowData;
      let ret = "";
      if (
        stateCode === "PURCHASE_REJECT" ||
        stateCode === "INSPECT_REJECT" ||
        stateCode === "INSPECT_DELETE"
      ) {
        ret = reason;
      } else {
        ret = etc || contentsHistory;
      }
      return ret;
    },
  },
};
</script>
