<template>
  <div>
    <div class="file_comm">
      <label :for="_uid" class="btn_fourthly btn_small" :disabled="isDisabled">파일 첨부하기</label>
      <input
        :id="_uid"
        ref="file"
        type="file"
        :multiple="canMultiple"
        :disabled="isDisabled"
        @change="onFileChange"
      />
    </div>
    <p class="desc_file_tip tc_red">
      <span class="material-icons" :data-ellip="invalidFileAlertText"> warning </span>
    </p>
    <span v-if="fileWtieTip" class="desc_file_tip tc_red">{{ fileWtieTip }}</span>
    <FileList :dataList="dataList" :isFileUploadCompleted="false" @onClickRemove="onClickRemove" />
  </div>
</template>

<script>
import FileList from "./FileList";
import ApiService from "@/services/ApiService";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "FileWrite",
  components: {
    FileList,
  },
  props: {
    dataList: Array,
    descFileTip: String,
    invalidFileExtension: Array,
    invalidFileAlertText: {
      type: String,
      default:
        "개별 첨부파일 최대 사이즈는 100MB 입니다.\nICO, EXE, DMG, GIF, TXT 확장자는 파일 첨부가 불가합니다.",
    },
    canMultiple: {
      type: Boolean,
      default: true,
    },
    fileWtieTip: String,
    isNeedAuth: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      //등록 한개로 제한
      // if(!this.canMultiple && this.dataList.length > 0){
      //   const itemToRemove = this.dataList[0];
      //   this.$emit( 'fileRemoved', itemToRemove );
      //   this.removeFiles( itemToRemove.attachNum );
      // }

      if (!this.invalidFileExtension) {
        this.uploadFiles(files);
        return;
      }

      this.checkValidFile(files, e.target);
    },
    checkValidFile(files, elInput) {
      var file;
      var strExtUpload;

      for (var i = 0; i < files.length; ++i) {
        file = files[i];

        strExtUpload = "." + file.name.split(".").pop();

        if (!this.getIsValidFileExt(strExtUpload, this.invalidFileExtension)) {
          this.alert("업로드 불가능한 확장자입니다.");
          elInput.value = "";
          return;
        }
      }

      this.uploadFiles(files);
    },
    getIsValidFileExt(strExtUpload, arrExtCheck) {
      var strExtCheck;

      for (var j = 0; j < arrExtCheck.length; j++) {
        strExtCheck = arrExtCheck[j];

        if (strExtUpload.toLowerCase() === strExtCheck.toLowerCase()) {
          return false;
        }
      }

      return true;
    },
    async uploadFiles(files) {
      const result = await ApiService.shared.postFiles(files);

      // if( !result.data ) {
      //   this.$emit( 'fileUploadedFailed', result.text );
      //   return;
      // }
      if (!result) {
        this.$store.dispatch(
          ALERT_ACTION.SHOW_ALERT,
          "네트워크 연결 상태가 좋지 않습니다.<br />확인 후 다시 후 다시 시도해주세요.",
        );
        return;
      }

      if (!result.data) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, { text: result.text });
        return;
      }

      this.$emit("fileUploaded", result.data);
    },
    onClickRemove(itemToRemove) {
      this.$emit("fileRemoved", itemToRemove);

      this.removeFiles(itemToRemove.sysFilePath);
    },
    async removeFiles(sysFilePath) {
      let path;

      if (this.isNeedAuth) {
        path = `${this.$apiPath.ATTACH_FILE}/${sysFilePath}`;
      } else {
        path = `${this.$apiPath.ATTACH_FILE}/${sysFilePath}/no`;
      }

      const result = await ApiService.shared.deleteData(path, this.isNeedAuth);

      this.$refs.file.value = null;
    },
  },
};
</script>

<style scoped>
.file_comm {
  display: inline-block;
  position: relative;
}
.file_comm label {
  display: block;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
}
.file_comm input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.01;
}
.desc_file_tip {
  display: inline-block;
  margin-left: 5px;
  font-size: 11px;
  color: #bbb;
  vertical-align: middle;
}
.file_comm.disabled label[disabled="disabled"] {
  color: #bbb;
  background-color: #fbfbfb;
  border-color: #e1e1e1;
}
.file_comm.disabled input[disabled="disabled"] {
  border-color: #e1e1e1;
  color: #bbb;
  background-color: #fbfbfb;
}
.desc_file_tip .ico_exclam_r {
  margin-right: 4px;
}
.desc_file_tip + .desc_file_tip {
  padding-left: 0;
}
</style>
