<template>
  <div class="area_view">
    <TableViewWithTitle title="발주내역">
      <template v-slot:colgroup>
        <col style="width: 56px" />
        <col />
        <col style="width: 159px" />
        <col style="width: 128px" />
        <col style="width: 164px" />
        <col style="width: 164px" />
        <col style="width: 164px" />
        <col style="width: 199px" />
      </template>
      <template v-slot:header>
        <tr>
          <th>No.</th>
          <th>품명</th>
          <th>입고처</th>
          <th>수량</th>
          <th>단가</th>
          <th>금액</th>
          <th>프로젝트 코드</th>
          <th>비고</th>
        </tr>
      </template>
      <template v-slot:body>
        <tr v-for="(item, index) in orderGoodsList" :key="index">
          <td class="align_center">
            {{ index + 1 }}
          </td>
          <td>{{ item.itemName }}</td>
          <td>{{ item.manufacturer }}</td>
          <td>{{ item.quantity }}{{ item.unitValue }}</td>
          <td class="align_right">{{ item.price }}{{ item.currency }}</td>
          <td class="align_right">{{ item.totalPrice }}{{ item.currency }}</td>
          <td>{{ item.projectCode }}</td>
          <td>{{ item.note }}</td>
        </tr>
      </template>
    </TableViewWithTitle>
    <TableView>
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col style="width: 428px" />
        <col style="width: 620px" />
      </template>
      <template v-slot:body>
        <!-- <tr>
          <th>총수량</th>
          <td>{{ resultAmountWithComma }}</td>
          <td rowspan="3" class="no_padding">
            <dl class="list_total">
              <dt>소계</dt>
              <dd>
                {{ resultPrice | currency }}<span class="txt_util">{{ currency }}</span>
              </dd>
              <dt>절사금액</dt>
              <dd>
                (-) {{ truncationPrice | currency }}<span class="txt_util">{{ currency }}</span>
              </dd>
              <dt class="dt_total">합계금액</dt>
              <dd class="dd_total">
                <span class="txt_price"
                  >{{ resultSumPrice | currency
                  }}<span class="txt_util">{{ currency }}</span></span
                >
              </dd>
            </dl>
          </td>
        </tr>
        <tr>
          <th>절사금액</th>
          <td>{{ truncationPriceWithComma }}</td>
        </tr>
        <tr>
          <th>비고</th>
          <td v-html="goodsNote || ''"></td>
        </tr> -->
      </template>
    </TableView>
  </div>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import TableView from "@/components/shared/tableView/TableView";

export default {
  name: "InspectOrderGoodsList",
  components: {
    TableViewWithTitle,
    TableView,
  },
  props: {
    orderGoodsList: Array,
  },
  data() {
    return {
      toPath: `${this.$routerPath.ORDER_VIEW}/${this.docId}`,
    };
  },
};
</script>
