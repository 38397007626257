<template>
  <Popup popupType="printType" :title="'검수확인서'">
    <div class="section_comm">
      <div class="body_section">
        <div class="box_print">
          <div id="wrapPrint" class="wrap_print">
            <div class="page_print">
              <template v-if="model.gubun === 'C'">
                <!--계약정보-->
                <InspectContractInfo
                  v-if="model.docId"
                  :model="model"
                  :contractInfo="model.contractInfo"
                  :currencyCode="model.currencyCode"
                />
              </template>
              <template v-else>
                <!-- 발주정보 -->
                <InspectOrderInfo
                  v-if="model.orderCid"
                  :orderCid="model.orderCid"
                  :orderInfo="model.orderInfo"
                  :currencyCode="model.currencyCode"
                />
              </template>

              <div class="tbl_print tbl_list">
                <table>
                  <colgroup>
                    <!-- 일시 -->
                    <col style="width: 157px" />
                    <!-- 상태 -->
                    <col style="width: 130px" />
                    <!-- 의견 -->
                    <col style="width: 357px" />
                    <!-- 담당자 -->
                    <col style="width: 135px" />
                    <!-- 첨부파일 -->
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>일시</th>
                      <th>상태</th>
                      <th>의견</th>
                      <th>담당자</th>
                      <th>첨부파일</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, index) in model.histories">
                      <InspectConfirmPopupLine :key="index" :rowData="item" />
                    </template>
                    <tr v-if="model.histories.length < 1">
                      <td colspan="6" class="td_empty">검색 결과가 없습니다</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sticky class="sticky">
      <button type="button" class="btn_fourthly btn_large" @click="onClickClose">닫기</button>
      <button type="submit" class="btn_tertiary btn_large" @click="print">인쇄</button>
    </Sticky>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import Sticky from "@/components/layout/content/Sticky.vue";

import Board from "@/components/common/board/Board";
import FileView from "@/components/common/file/FileView";
import InspectContractInfo from "@/components/admin/inspect/write/InspectContractInfo";
import InspectOrderInfo from "@/components/admin/inspect/write/InspectOrderInfo";
import InspectConfirmPopupLine from "@/components/admin/inspect/write/InspectConfirmPopupLine.vue";

import ApiService from "@/services/ApiService";

import inspectStatusList from "@/constants/inspectStatusList";
import { getDateString } from "@/utils/dateUtils";
import { addCommaForMoney } from "@/utils/stringUtils";
import { getDateStringWithTime } from "@/utils/dateUtils";

import { Printd } from "printd";
import printcss from "@/constants/printcss.js";

export default {
  name: "InspectPrintPopup",
  components: {
    Popup,
    Sticky,
    Board,
    FileView,
    InspectContractInfo,
    InspectOrderInfo,
    InspectConfirmPopupLine,
  },
  props: {
    model: Object,
  },

  computed: {
    // histories() {
    //   const { contractInspectHistoryList } = this.inspectData;
    //   return contractInspectHistoryList || [];
    // },
    // isInitial() {
    //   //현업검수일 경우
    //   const { stateCode } = this.lastestHistory;
    //   return stateCode == 'INITIAL';
    // },
    // lastestHistory() {
    //   if (this.histories.length === 0) return null;
    //   // 맨 뒤가 현재 상태로 변경.
    //   // return this.histories[ 0 ];
    //   return this.histories[this.histories.length - 1];
    // },
  },
  methods: {
    print() {
      const wrapPrint = document.getElementById("wrapPrint");
      const d = new Printd();

      // printd 라이브러리 버그로 인해 그냥 스트링을 가져오는 걸로 임시 처리. 추후 변경 필요.
      // 버그 내용: css를 읽었다 안읽었다 한다.
      // const styles = [
      //   '/assets/css/print.css'
      // ]

      const styles = [printcss];

      d.print(wrapPrint, styles);
    },
    regDateWithTime(regDate) {
      // const { regDate } = this.item;
      if (!regDate) return "";
      // return regDate;
      return getDateStringWithTime(regDate);
    },
    stateCodeName(stateCodeName, stateCode) {
      // const { stateCodeName, stateCode } = this.item;
      if (stateCodeName) return stateCodeName;

      const result = inspectStatusList.find((item) => item.code === stateCode);
      if (!result) return "";

      return result.text;
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/css/print.css";
.page_print + .page_print {
  border-top: 1px solid #e1e1e1;
}
</style>
