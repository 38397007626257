<template>
  <div class="area_view">
    <TableHead title="검수취소내역">
      <template v-slot:headLeft>
        <p v-if="isIncludeVat" class="desc_tip tc_red">* 부가세(VAT) 포함</p>
        <p v-if="isNotIncludeVat" class="desc_tip tc_red">* 부가세(VAT) 별도</p>
      </template>
    </TableHead>
    <Board :disableHover="true" :dataList="dataList || []" :showPagination="false">
      <template v-slot:colgroup>
        <!-- NO. -->
        <col style="width: 62px" />
        <!-- 품명 -->
        <col style="width: 226px" />
        <!-- 발주수량 -->
        <col style="width: 120px" />
        <!-- 단가 -->
        <col style="width: 100px" />
        <!-- 발주금액 -->
        <!-- <col style="width: 100px" /> -->
        <!-- 취소수량 -->
        <col style="width: 100px" />
        <!-- 금액 -->
        <col style="width: 120px" />
        <!-- 프로젝트 코드 -->
        <!-- <col style="width: 152px" /> -->
        <!-- 비고 -->
        <col style="width: 102px" />
      </template>
      <template v-slot:tr>
        <th>No.</th>
        <th>품명</th>
        <th>발주 수량</th>
        <th>단가</th>
        <th>발주 금액</th>
        <!-- <th>납품 수량</th> -->
        <th>취소 수량</th>
        <!-- <th>기 검수 금액</th> -->
        <th>품목상세</th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in dataList || []">
          <tr :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <template>{{ item.itemName }}</template>
            </td>
            <td>
              {{ item.quantity }}
            </td>
            <td class="align_right">{{ item.price | currency }}</td>
            <td class="align_right">{{ item.totalPrice | currency }}</td>
            <!-- <td class="align_right">{{ item.deliveryQuantity | currency }}</td> -->
            <td class="align_right">{{ item.deleteInspectAmount | currency }}</td>
            <!-- <td class="align_right">
              {{ item.inspectPrice | currency }}
            </td> -->
            <td>
              {{ item.note }}
            </td>
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="7" class="td_empty">납품내역이 없습니다.</td>
        </tr>
      </template>
    </Board>
    <!-- <template>
      <div class="tbl_foot">
        <div class="area_total">
          <dl class="list_total">
            <dt class="">기 검수 금액</dt>
            <dd class="">
              <span class="txt_price align_right"
                >{{ totalPrice | currency }}<span class="txt_util">{{ currency }}</span></span
              >
            </dd>
          </dl>
        </div>
      </div>
    </template> -->
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";

import TableView from "@/components/shared/tableView/TableView";

import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";
import { getCountWithCurrency, getCountWithQuantity } from "@/utils/spUtils";

export default {
  name: "InspectItemInfo",
  components: {
    TableHead,
    Board,

    TableView,
  },
  props: {
    dataList: Array,
    orderGoodsRequests: Array,
    truncationPrice: String | Number,
    note: String,
    currency: String,
    unitValue: String,
    assetType: String,
    vat: String,
    degree: String | Number,
  },
  computed: {
    totalPrice() {
      let result = 0;
      this.dataList.forEach((item) => {
        let inspectPrice = item.inspectPrice ? getMoneyToNumber(item.inspectPrice) : 0;
        result = result + inspectPrice;
      });

      return result;
    },

    // noteSync: {
    //   get() {
    //     return this.note;
    //   },
    //   set(value) {
    //     this.$emit('update:note', value);
    //   },
    // },
    isIncludeVat() {
      if (this.vat && this.vat == "포함") {
        return true;
      }
      return false;
    },
    isNotIncludeVat() {
      if (this.vat && (this.vat == "별도" || this.vat == "")) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // getCountWithCurrency(count, value) {
    //   return getCountWithCurrency(count, value);
    // },
    // getCountWithQuantity(count, value) {
    //   return getCountWithQuantity(count, value);
    // },
    // alert(text) {
    //   this.$emit('alert', text);
    // },
    // createItem() {
    //   const item = new InspectItemModel();
    //   return item;
    // },
    // onClickRemove(itemToRemove) {
    //   const arr = this.dataList.filter((item) => item !== itemToRemove);
    //   this.$emit('update:dataList', arr);
    // },
    // onClickAdd() {
    //   if (this.dataList.length >= 10) {
    //     this.alert('총 10개까지 가능합니다.');
    //     return;
    //   }
    //   this.addItem();
    // },
    // addItem() {
    //   const arr = this.dataList;
    //   const item = this.createItem();
    //   arr.push(item);
    //   this.$emit('update:dataList', arr);
    // },
  },
};
</script>
