<template>
  <div
    :class="[
      'textarea_comm',
      { disabled: isDisabled, textarea_error: isError, textarea_count: isCount },
    ]"
  >
    <textarea
      ref="textarea"
      v-model="input"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :disabled="isDisabled"
    />
    <p v-if="isCount" class="desc_textarea">
      <em :class="{ on: countingValue != 0 }">{{ countingValue }}</em> &#47; {{ maxLength }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    value: String,
    maxLength: {
      type: Number,
      default: 500,
    },
    placeholder: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isCount: {
      type: Boolean,
      default: false,
    },
    isFocusOnMount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        if (this.maxLength && this.value.length > this.maxLength) {
          this.$emit("update:value", this.value.slice(0, this.maxLength));
        }

        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    countingValue() {
      return this.value.length;
    },
  },
  mounted() {
    if (this.isFocusOnMount) {
      this.$refs.textarea.focus();
    }
  },
};
</script>

<style scoped>
.textarea_comm textarea {
  width: 100%;
  height: 100%;
  padding: 6px 12px;
  border: 1px solid #e1e1e1;
  font-size: 12px;
  line-height: 20px;
  color: #555;
  outline: none;
  box-sizing: border-box;
  resize: vertical;
  vertical-align: top;
}
.textarea_comm textarea::-webkit-input-placeholder {
  color: #bbb;
}
.textarea_comm textarea:-moz-placeholder {
  color: #bbb;
}
.textarea_comm textarea::-moz-placeholder {
  color: #bbb;
}
.textarea_comm textarea:-ms-input-placeholder {
  color: #bbb;
}
.textarea_comm:focus-within textarea {
  border-color: #555;
}
.textarea_comm.disabled textarea,
.textarea_comm textarea:disabled {
  border-color: #f0f0f0;
  background-color: #fbfbfb;
  color: #bbb;
}
.textarea_comm.textarea_error textarea {
  border-color: #f8626c;
}
.textarea_comm .desc_textarea {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #888;
  text-align: right;
}
.textarea_comm .desc_textarea em.on {
  color: #555;
}
.textarea_count {
  padding-bottom: 22px;
}
</style>
