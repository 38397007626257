<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">
          <template v-if="model.stateCode !== 'INSPECT_DELETE'">
            {{ model.stateCode === "INSPECT_COMPLETE" ? "검수 완료" : "검수중" }}
          </template>
          <template v-else>
            {{ "검수 취소" }}
          </template>
        </h2>
      </div>
      <div class="body_section">
        <!-- 발주정보 -->
        <InspectOrderInfo :orderCid="model.orderCid" :orderInfo="model.orderInfo" />
        <template v-if="model.stateCode !== 'INSPECT_DELETE'">
          <InspectItemInfo ref="InspectItemInfo" :orderGoodsRequests="model.inspectItemList" />
        </template>
        <template v-else>
          <InspectCancelItemInfo :dataList="model.deleteOrderGoodsList" />
        </template>

        <div class="area_view">
          <InspectPriceHistory
            v-if="model.stateCode !== 'INITIAL'"
            :dataList="model.degreeSumList"
          />
          <!-- 검수내역-->
          <InspectHistory :dataList="model.histories" />
        </div>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <template v-if="isShowInspectPrintBtn">
          <!-- <button type="button" class="btn_fourthly btn_large" @click="onClickInspectPrint">
            검수확인서
          </button> -->
        </template>
        <button
          v-if="isShowRequestCancelBtn"
          type="button"
          class="btn_secondary btn_large"
          @click="onClickRequestCancel"
        >
          검수요청취소
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <InspectPrintPopup
        v-if="popup.isPopInspectPrint"
        :model="model"
        @onClickClose="onClickCloseInspectPrint"
      />
      <AlertPopup
        v-if="popup.isShowRequestCancel"
        alertText="검수요청을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseRequestCancel"
        @onClickPrimary="onClickSubmitRequestCancel"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";

import FileWrite from "@/components/common/file/FileWrite.vue";
import FileView from "@/components/common/file/FileView.vue";
import DatePicker from "@/components/common/calendar/DatePicker.vue";
import Sticky from "@/components/layout/content/Sticky";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import InspectContractInfo from "@/components/admin/inspect/write/InspectContractInfo";
import InspectOrderInfo from "@/components/admin/inspect/write/InspectOrderInfo";
import InspectCancelItemInfo from "@/components/admin/inspect/view/InspectCancelItemInfo";
import InspectOrderGoodsList from "@/components/admin/inspect/write/InspectOrderGoodsList";
import InspectHistory from "@/components/admin/inspect/write/InspectHistory";
import InspectPrintPopup from "@/components/admin/inspect/popup/InspectPrintPopup";
import InspectRejectPopup from "@/components/admin/inspect/popup/InspectRejectPopup";

import InspectItemInfo from "@/components/admin/inspect/view/InspectItemInfo";
import InspectPriceHistory from "@/components/admin/inspect/write/InspectPriceHistory";

// import { getDateString, dateToString, stringToDate } from '@/utils/dateUtils';
// import { addCommaForMoney } from '@/utils/stringUtils';
// import { getCountWithCurrency } from '@/utils/spUtils';
import { makePathWithQuery } from "@/utils/urlUtils";

// import {
//   INITIAL,
//   REQUESTER_INSPECT,
//   REQUESTER_REJECT,
//   PURCHASE_INSPECT,
//   PURCHASE_CONFIRM,
//   INSPECT_REQUEST,
//   COMPLETE,
// } from '@/constants/inspectStatusList';

import ApiService from "@/services/ApiService";
// import LocalStorageManager from '@/LocalStorageManager';
import InspectModel from "./InspectModel.js";

import PageMixin from "@/mixins/PageMixin";

export default {
  name: "InspectView",
  components: {
    PageWithLayout,
    TableHead,
    Sticky,
    TableViewWithTitle,
    FileWrite,
    FileView,
    Input,
    DatePicker,
    AlertPopup,

    InspectContractInfo,
    InspectOrderInfo,
    InspectOrderGoodsList,
    InspectCancelItemInfo,
    InspectHistory,
    InspectPrintPopup,
    InspectRejectPopup,
    InspectItemInfo,
    InspectPriceHistory,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new InspectModel(),
      popup: {
        isPopInspectPrint: false,
        isShowRequestCancel: false,
      },
    };
  },
  computed: {
    // 검수확인서 버튼 노출 조건
    isShowInspectPrintBtn() {
      // 검수완료인 경우
      const { stateCode } = this.model;
      return stateCode === "INSPECT_COMPLETE";
    },
    // 검수요청취소 버튼 노출 조건
    isShowRequestCancelBtn() {
      // 검수요청 단계인 경우
      const { stateCode } = this.model;
      return stateCode === "INSPECT_REQUEST";
    },
  },
  created() {
    const { refPk } = this.$route.params;
    this.model.inspectNum = refPk;
    this.initData();
  },
  methods: {
    // 데이터 초기화
    async initData() {
      await this.getInspectDetailData();
    },
    // 검수 상세 데이터 가져오기
    async getInspectDetailData() {
      const { inspectNum } = this.model;
      const path = `${this.$apiPath.ST_INSPECT}/history/${inspectNum}`;
      const result = await ApiService.shared.get(path);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }
      this.model.setData(result.data);
      this.model.setInspectInfo(result.data);
      //this.model.setInspectItemInfoData(result.data);
      this.model.setOrderInfoData(result.data);
    },
    // 상태 체크 (보기/작성 페이지 접근 적절성)
    validState() {
      const { inspectNum, stateCode } = this.model;
      if (stateCode === "INITIAL") {
        const path = makePathWithQuery(
          this.$routerPath.INSPECT_WRITE,
          `${inspectNum}`,
          this.$route.query,
        );
        this.$router.push(path);
      }
    },
    // 목록 이동 처리
    gotoList() {
      this.$router.push({
        path: this.$routerPath.INSPECT_LIST,
        query: this.$route.query,
      });
    },
    // 검수확인서 버튼
    onClickInspectPrint() {
      this.popup.isPopInspectPrint = true;
    },
    // 검수확인서 팝업닫기
    onClickCloseInspectPrint() {
      this.popup.isPopInspectPrint = false;
    },
    // 목록이동 버튼
    onClickGoToList() {
      this.gotoList();
    },
    // 검수요청 취소
    onClickRequestCancel() {
      this.popup.isShowRequestCancel = true;
    },
    // 검수요청취소 팝업 > 취소
    onClickCloseRequestCancel() {
      this.popup.isShowRequestCancel = false;
    },
    // 검수요청취소 팝업 > 확인
    async onClickSubmitRequestCancel() {
      const { inspectNum } = this.model;
      const path = `${this.$apiPath.ST_INSPECT}/${inspectNum}`;
      const result = await ApiService.shared.deleteData(path);

      this.popup.isShowRequestCancel = false;

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.gotoList();
    },
  },
};
</script>
