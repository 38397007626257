<template>
  <div class="area_view">
    <TableViewWithTitle title="계약정보">
      <template v-slot:colgroup>
        <col style="width: 200px" />
        <col style="width: 200px" />
        <col />
        <col style="width: 180px" />
        <col style="width: 180px" />
        <col style="width: 120px" />
        <col style="width: 120px" />
      </template>
      <template v-slot:header>
        <tr>
          <th>기간</th>
          <th>계약명</th>
          <th>파트너사</th>
          <th>금액</th>
          <th>검수금액</th>
          <th>납품완료일</th>
          <th>검수완료일</th>
        </tr>
      </template>
      <template v-slot:body>
        <tr>
          <td class="align_center">
            {{ contractInfo.contractStartDate | dateStringFormat }} ~
            {{ contractInfo.contractEndDate | dateStringFormat }}
          </td>
          <td>
            <router-link :to="toPath" class="link_subject" target="_blank" rel="opener">
              {{ contractInfo.description }}
            </router-link>
          </td>

          <td class="align_center">
            {{ contractInfo.companyName }}
          </td>
          <td class="align_right">{{ contractInfo.finalPrice | currency }} {{ currencyCode }}</td>
          <td class="align_right">
            {{ contractInfo.inspectPrice | currency }}
            {{ currencyCode }}
          </td>
          <td class="align_center">
            {{ contractInfo.deliveryCompleteDate | dateStringFormat | nullToDash }}
          </td>
          <td class="align_center">
            {{ contractInfo.inspectCompleteDate | dateStringFormat | nullToDash }}
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
  </div>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "InspectContractInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    model: Object,
    contractInfo: Object,
    currencyCode: String,
  },
  data() {
    return {
      toPath: `${this.$routerPath.CONTRACT_VIEW}/${this.model.docId}`,
    };
  },
};
</script>
