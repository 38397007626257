<template>
  <div class="area_view">
    <TableViewWithTitle title="발주정보">
      <template v-slot:body>
        <tr>
          <th>발주일</th>
          <td>{{ orderInfo.goodsDate | dateStringFormat }}</td>
          <th>발주완료일</th>
          <td>{{ orderInfo.goodsDeadline | dateStringFormat }}</td>
        </tr>
        <tr>
          <th>파트너사</th>
          <td>
            {{ orderInfo.companyName }}
          </td>
          <th>검수완료일</th>
          <td>
            {{ orderInfo.inspectCompleteDate | dateStringFormat | nullToDash }}
          </td>
        </tr>
        <tr>
          <th>발주명</th>
          <td colspan="3">
            {{ orderInfo.title }}
          </td>
        </tr>
        <tr>
          <th>발주서</th>
          <td colspan="3">
            <ConnectViewOnlyOne
              :dataList="orderInfo"
              :isDisabled="orderInfo.aprvlStatus == '-'"
              @onClickConnect="onClickConnectOrderRequestWf"
            />
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
  </div>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne.vue";

export default {
  name: "InspectOrderInfo",
  components: {
    TableViewWithTitle,
    ConnectViewOnlyOne,
  },
  props: {
    orderCid: String,
    orderInfo: Object,
    currencyCode: String,
  },
  data() {
    return {
      toPath: `${this.$routerPath.ORDER_VIEW}/${this.orderCid}`,
    };
  },
  methods: {
    onClickConnectOrderRequestWf() {
      // const { refPk } = item;
      const path = `${this.$routerPath.ORDER_VIEW}/${this.orderCid}`;
      this.$windowOpen(path);
    },
  },
};
</script>
<style scoped></style>
