<template>
  <div class="area_view">
    <TableHead :title="title" :isEssential="isEssential">
      <template v-slot:headLeft>
        <slot name="headLeft" />
      </template>
      <template v-slot:headRight>
        <slot name="headRight" />
      </template>
    </TableHead>
    <TableView :title="title" :class="tableViewClass">
      <template v-slot:colgroup>
        <slot name="colgroup" />
      </template>
      <template v-slot:header>
        <slot name="header" />
      </template>
      <template v-slot:body>
        <slot name="body" />
      </template>
      <template v-slot:custom>
        <slot name="custom" />
      </template>
    </TableView>
    <slot name="foot" />
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import TableView from "./TableView";
export default {
  name: "TableViewWithTitle",
  components: {
    TableHead,
    TableView,
  },
  props: {
    title: String,
    tableViewClass: String,
    isEssential: Boolean,
  },
};
</script>
